<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--secondary-color)">
      <v-row>
        <v-col sm="12" md="6" lg="4" xl="4">
          <FormSelect
            label="Plataforma"
            v-model="selectedPlatform"
            :options="plataforms"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="6" lg="4" xl="4">
          <FormField label="Filtro" v-model="filter" :clearable="true" />
        </v-col>
      </v-row>
      <v-row class="static-texts-container">
        <v-col v-show="isSite">
          <div v-for="(s, i) in staticTexts.ecommerce" :key="i">
            <FormField v-show="s.show" :label="s[0]" v-model="s[1]" />
          </div>
        </v-col>
        <v-col v-show="isAplicativo">
          <div v-for="(s, i) in staticTexts.app" :key="i">
            <FormField v-show="s.show" :label="s[0]" v-model="s[1]" />
          </div>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-col class="d-flex justify-end" cols="12"
          ><FormButton type="perigo" @click="onRestaura">Restaurar</FormButton>
          <FormButton
            type="submissao"
            @click="onSave"
            style="margin-left:5px"
            submit
          >
            Salvar
          </FormButton>
        </v-col>
      </v-row>
    </Panel>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import { mapGetters } from 'vuex'
import Api from '../../../service/api-web'
import { ERRORS_SHOW } from '@/store/actions/errors'
import * as _ from 'lodash'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'MensagensAutomaticas',
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    isSite() {
      return this.selectedPlatform === 'ecommerce'
    },
    isAplicativo() {
      return this.selectedPlatform === 'app'
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  components: {
    Panel,
    FormButton,
    FormSelect,
    FormField,
    MarketingModules
  },
  data: () => ({
    staticTexts: {
      app: [],
      ecommerce: []
    },
    filter: '',
    isFilterOnFocus: false,
    hiddenSufix: 'hidden',
    plataforms: [
      { text: 'Aplicativo', value: 'app' },
      { text: 'Site', value: 'ecommerce' }
    ],
    selectedPlatform: 'app',
    modulosForm: [ModulesEnum.TODOS]
  }),
  created() {
    this.initStaticTexts()
  },
  watch: {
    filter() {
      this.filterStaticTexts()
    }
  },
  methods: {
    initStaticTexts() {
      Api.v4.staticText
        .getAll(this.getFornecedorId)
        .then(res => {
          const translation = _.get(res, 'data.translation') || null
          if (translation) {
            let ecommerce =
              translation &&
              translation.ecommerce &&
              Object.entries(translation.ecommerce)
            let app =
              translation && translation.app && Object.entries(translation.app)
            ecommerce = this.getFormatedList(
              ecommerce.filter(s => !s[0].includes(this.hiddenSufix))
            )
            app = this.getFormatedList(
              app.filter(s => !s[0].includes(this.hiddenSufix))
            )
            this.staticTexts = {
              ecommerce,
              app
            }
          }
        })
        .catch(err => this.$store.dispatch(ERRORS_SHOW, err))
    },
    getFormatedList(list) {
      return list.map(s => {
        return { ...s, show: true }
      })
    },
    filterStaticTexts() {
      if (this.filter) {
        this.staticTexts[this.selectedPlatform] = this.staticTexts[
          this.selectedPlatform
        ].map(s => {
          const checkLabel = s[0]
            .toLowerCase()
            .includes(this.filter.toLowerCase())
          const checkValue = s[1]
            .toLowerCase()
            .includes(this.filter.toLowerCase())
          return { ...s, show: checkLabel || checkValue }
        })
      } else {
        this.staticTexts[this.selectedPlatform] = this.getFormatedList(
          this.staticTexts[this.selectedPlatform]
        )
      }
    },
    onSave() {
      let body = {
        ecommerce: null,
        app: null
      }
      this.staticTexts.ecommerce.forEach(s => {
        body.ecommerce = { ...body.ecommerce, [s[0]]: s[1] }
      })
      this.staticTexts.app.forEach(s => {
        body.app = { ...body.app, [s[0]]: s[1] }
      })
      Api.v4.staticText
        .save(this.getFornecedorId, body)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Textos estáticos atualizados com sucesso'
          )
        })
        .catch(err => this.$store.dispatch(ERRORS_SHOW, err))
    },
    onRestaura() {
      Api.v4.staticText
        .restore(this.getFornecedorId)
        .then(() => {
          this.initStaticTexts()
          this.$vueOnToast.pop(
            'success',
            'Textos estáticos restaurados com sucesso'
          )
        })
        .catch(err => this.$store.dispatch(ERRORS_SHOW, err))
    }
  }
}
</script>

<style scoped>
.static-texts-container {
  max-height: 450px;
  overflow-y: auto;
}
</style>
